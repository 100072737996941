<template>
  <div class="switcher-wrapper">
    <button
      @click="ru.action"
      :class="{ active: ru.isActive, 'switcher-wrapper__button': true }"
    >
      Рус
    </button>
    <button
      @click="kz.action"
      :class="{ active: kz.isActive, 'switcher-wrapper__button': true }"
    >
      Қаз
    </button>
  </div>
</template>

<script setup>
import { markRaw, reactive } from "vue";
import { useI18n } from "vue-i18n";

const locale = useI18n().locale;

const ru = reactive({
  isActive: localStorage.getItem("language") === "ru",
  action: markRaw(() => {
    localStorage.setItem("language", "ru");
    kz.isActive = false;
    ru.isActive = true;
    locale.value = "ru";
  }),
});
const kz = reactive({
  isActive: localStorage.getItem("language") === "kz",
  action: markRaw(() => {
    localStorage.setItem("language", "kz");
    ru.isActive = false;
    kz.isActive = true;
    locale.value = "kz";
  }),
});
</script>

<style scoped>
.switcher-wrapper {
  display: flex;
  align-items: center;
}
.switcher-wrapper__button {
  background: rgb(19, 19, 19);
  border: 2px solid #fff;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.switcher-wrapper__button:nth-child(odd) {
  margin-right: 5px;
}
.active {
  border: 2px solid #6bbf43;
  color: #6bbf43;
}
</style>
