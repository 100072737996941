<template>
  <router-view></router-view>
</template>
<script setup>
import { useI18n } from "vue-i18n";

const i18n = useI18n({});
i18n.locale.value = localStorage.getItem("language");
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fs {
  &-16 {
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 19px;
  }
  &-20 {
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 24px;
  }
  &-24 {
    font-family: Inter, sans-serif;
    font-size: 24px;
    line-height: 29px;
  }
  &-32 {
    font-family: Inter, sans-serif;
    font-size: 32px;
    line-height: 39px;
  }
}
@media screen and (max-width: 740px) {
  .fs {
    &-16 {
      font-family: Inter, sans-serif;
      font-size: 14px;
      line-height: 16px;
    }
    &-20 {
      font-family: Inter, sans-serif;
      font-size: 16px;
      line-height: 18px;
    }
    &-24 {
      font-family: Inter, sans-serif;
      font-size: 18px;
      line-height: 20px;
    }
    &-32 {
      font-family: Inter, sans-serif;
      font-size: 22px;
      line-height: 28px;
    }
  }
}
</style>
