import { instance } from "@/plugins/axios";

export const pdf = async (code, session) => {
  const payload = {
    code,
    main_session: session,
  };
  try {
    return await instance.post("/pdf/", payload);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
