<template>
  <document-frame v-bind="stepper.current" />
</template>

<script setup>
import { toRefs } from "vue";
import DocumentFrame from "@/components/document/DocumentFrame.vue";
import useStepper from "@/utils/stepper";

const stepper = useStepper({
  queue: ["front", "back"],
  steps: {
    front: {
      state: "neutral",
      text: "document.front",
    },
    back: {
      state: "neutral",
      text: "document.back",
    },
  },
  success: (step) => (step.state = "success"),
  failure: (step) => (step.state = "failure"),
  transition: (step) => (step.state = "success"),
});

// eslint-disable-next-line no-undef
defineExpose(toRefs(stepper));
</script>
