<template>
  <div class="wrapper">
    <custom-alert
      text="common.alert.text"
      v-if="isAlert"
      :class="{ alert_active: isAlert }"
    />
    <div class="header">
      <h1 class="header__main fs-32">{{ $t("request.header") }}</h1>
      <h2 class="header__secondary fs-16">
        {{ $t("request.sub-header") }}
      </h2>
    </div>
    <div class="form">
      <h2 class="form__header fs-20">{{ $t("request.form.header") }}</h2>
      <form action="" class="form__content">
        <custom-input
          width="100%"
          v-model:error="phone.error"
          v-model:value="phone.data"
          :validator="phone.validate"
          :mask="phoneMask"
          placeholder="request.form.phone-placeholder"
          :caret-position="true"
          label="request.form.phone-label"
          name="phone"
          input-type="tel"
          counter="18"
          :required="true"
        />
        <custom-input
          width="100%"
          @keydown.enter.prevent="button.submit"
          v-model:error="iin.error"
          v-model:value="iin.data"
          :validator="iin.validate"
          placeholder="request.form.iin-placeholder"
          label="request.form.iin-label"
          name="iin"
          input-type="tel"
          counter="12"
          :required="true"
        />
      </form>
      <div class="form__approvement">
        <div>
          <p>
            {{ $t("request.agreement-part1") }}
            <a
              href="https://antiazart.kz/documents/agreement.pdf"
              target="_blank"
            >
              {{ $t("request.agreement-part2") }}
            </a>
          </p>
        </div>
        <div>
          <language-switcher />
        </div>
      </div>
      <custom-button
        text="common.button"
        width="70%"
        @click="button.submit"
        :error="button.error"
        :loading="button.loading"
        :disabled="isDisabled"
      />
    </div>
    <div class="wrapper__help-text fs-16">
      <a @click="router.push('/info')">{{ $t("request.info") }}</a>
    </div>
    <div class="footer fs-16">
      <div class="footer-content">
        <div class="footer-content__left fs-16">
          <p>{{ $t("common.footer.copyright") }}</p>
        </div>
        <div class="footer-content__right fs-16">
          <p>{{ $t("common.footer.mail") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { markRaw, reactive, computed, onBeforeMount, ref } from "vue";
import router from "@/router";
import CustomInput from "@/components/CustomInput";
import CustomButton from "@/components/CustomButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { requestCode } from "@/http/edocument";
import { createSession } from "@/http/session";
import { useUserDataStore } from "@/stores/userData";
import { useSessionStore } from "@/stores/session";
import { useTokenStore } from "@/stores/token";
import { setApiKey } from "@/plugins/axios";
import { changeBackendUrl } from "@/plugins/axios";

const userDataStore = useUserDataStore();
const sessionStore = useSessionStore();
const tokenStore = useTokenStore();

let formattedPhone;
const isAlert = ref(false);

const phone = reactive({
  data: "+7(   )   -  -  ",
  error: "",
  validate: markRaw((value) => {
    if (value.length === 0) return "common.error.empty";
    value = value.replace(/\D+/g, "");
    if (value.length !== 11) return "common.error.length";
    return "";
  }),
});
const iin = reactive({
  data: "",
  error: "",
  validate: markRaw((value) => {
    if (value.length === 0) return "common.error.empty";
    if (!/^\d{12}$/.test(value)) return "common.error.length";
    return "";
  }),
});
const button = reactive({
  error: "",
  loading: false,
  submit: markRaw(async () => {
    button.error = "";
    button.loading = true;
    formattedPhone = phone.data.match(/\d/g).join("");
    setApiKey(tokenStore.token);
    changeBackendUrl("https://test.biometric.kz/v1");
    const response = await requestCode(iin.data, formattedPhone);

    if (response?.status >= 400 || !response) {
      isAlert.value = true;
    }

    if (response?.status === 200) {
      userDataStore.iin = iin.data;
      userDataStore.phone = formattedPhone;
      await router.push("/approve-form");
    } else if (response?.data?.detail === "Subject not found in MCDB") {
      button.error = "common.error.mcdb";
    } else {
      button.error = response.data.detail || "common.error.something-wrong";
    }

    button.loading = false;
  }),
});

const phoneMask = (value) => {
  let caret = 0;
  const template = "+7(   )   -  -  ".split("");
  if (value[0] === "+") value = "+7" + value.slice(2, value.length);
  else value = "+7" + value.slice(1, value.length);
  const numbers = value.replace("+7").replace(/\D+/g, "");
  for (let i = 0; i < numbers.length; i++) {
    const index = template.indexOf(" ");
    template[index] = numbers[i];
    caret = index + 1;
  }
  if (caret === 0) caret = 3;
  return { value: template.join(""), caretPosition: caret };
};

const isDisabled = computed(() => {
  if (iin.error.length > 0 || iin.data.length === 0) return true;
  if (phone.error.length > 0 || phone.data.length === 0) return true;

  return false;
});

onBeforeMount(async () => {
  userDataStore.isFromFirstPage = true;
  setApiKey(tokenStore.token);
  changeBackendUrl("https://test.biometric.kz/v1");
  sessionStore.session = (await createSession()).data.session;
});
</script>

<style scoped>
.wrapper {
  background: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.header {
  margin-top: 50px;
}
.header__main,
.header__secondary,
.form__header,
.wrapper__help-text {
  text-align: center;
  text-transform: uppercase;
}
.header__main,
.form__header,
.wrapper__help-text a {
  color: #fff;
}
.wrapper__help-text a {
  cursor: pointer;
  text-decoration: underline;
}
.header__secondary {
  margin-top: 40px;
  color: #6bbf43;
}
.form {
  width: 70%;
  max-width: 700px;
}
.form__header {
  margin-bottom: 30px;
}
.form__content {
  background: #ffffff36;
  padding: 25px 50px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 270px;
}
.form__approvement {
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.form__approvement p {
  color: #fff;
}
.form__approvement p a {
  color: #6bbf43;
}
.wrapper__help-text {
  font-size: 20px;
}
.footer {
  height: 50px;
  background: #ffffff36;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.footer-content {
  height: 100%;
  width: 730px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-content__right,
.footer-content__left {
  color: #ffffffc2;
  font-size: 16px;
}
.footer-content__left {
  margin-left: 20px;
}
.footer-content__right {
  margin-right: 20px;
}

@media screen and (max-width: 650px) {
  .header {
    margin-top: 25px;
  }
  .form {
    width: 95%;
  }
  .form__content {
    /*padding: 30px 30px;*/
    width: 100%;
  }
  .header__secondary {
    margin: 15px 5px 15px 5px;
  }
  .wrapper__help-text {
    margin-top: 15px;
  }
  .footer-content {
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-content__left,
  .footer-content__right {
    margin: 5px;
  }
}
</style>
