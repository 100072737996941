<template>
  <main>
    <div class="container">
      <main class="main">
        <img
          class="main__img"
          v-if="statement"
          src="../assets/result/success-circle.svg"
        />
        <img
          class="main__img"
          v-else-if="!statement"
          src="../assets/result/failure-circle.svg"
        />
        <h3 class="main__h3">
          {{ $t(current.title) }}
        </h3>
        <p class="main__p">
          {{ $t(current.subtitle) }}
        </p>
        <custom-button
          v-if="!statement"
          width="100%"
          :text="current.button_text"
          @click="button.submit"
          :error="button.error"
          :loading="button.loading"
          :background-color="current.color"
          text-color="#fff"
        />
      </main>
    </div>
  </main>
</template>

<script setup>
import { markRaw, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import router from "@/router";
import CustomButton from "@/components/CustomButton.vue";
import { useUserDataStore } from "@/stores/userData";

// eslint-disable-next-line no-undef
const props = defineProps({
  success: {
    type: String,
    required: true,
  },
});

const statement = props.success === "true";
const userDataStore = useUserDataStore();

const statuses = {
  success: {
    title: "result.success.header",
    subtitle: "result.success.text",
    color: "#6bbf43",
  },
  failure: {
    title: "result.failure.header",
    subtitle: "result.failure.text",
    button_text: "result.failure.button",
    color: "#FF4D4F",
  },
};

const button = reactive({
  error: "",
  loading: false,
  submit: markRaw(async () => {
    router.push("/");
  }),
});

const current = ref(statuses[statement ? "success" : "failure"]);

onMounted(() => {
  if (!userDataStore.isFromFirstPage) router.push("/");
});

onBeforeUnmount(() => {
  window.location.reload();
});
</script>

<style scoped>
.main {
  background: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.main__h3 {
  color: v-bind(current[ "color" ]);
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.main__p {
  color: #fff;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.main__button {
  border: none;
  background-color: #1890ff;
  padding: 8px 40px;
  margin-top: 40px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
}

.main__table {
  width: 500px;
  border-collapse: collapse;
}

.main__table tr {
  border-collapse: collapse;
}

.main__table tr td {
  border: 1px solid #000;
  max-width: 50%;
  padding: 10px;
}

.main__table-break {
  word-break: break-word;
}

.long-text {
  margin-top: 20px;
  display: block;
  max-width: 80vw;
  word-break: break-all;
}

@media screen and (max-width: 650px) {
  .main {
    max-width: 100vw;
  }

  .main__table {
    width: 100%;
  }
}
</style>
