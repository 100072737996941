import { instance } from "@/plugins/axios";

export const requestCode = async (iin, phone) => {
  const payload = {
    iin,
    phone,
  };
  try {
    return await instance.post(
      "/e-document/v2/online-access/request/",
      payload
    );
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};

export const approveCode = async (session, iin, code) => {
  const payload = {
    main_session_id: session,
    iin,
    code,
  };
  try {
    return await instance.post("e-document/v2/online-access/approve/", payload);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
