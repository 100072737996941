export async function getVideoStreamData(
  camera,
  width = 1280,
  height = 720,
  deviceId = null
) {
  const constraints = {
    video: {
      width: {
        min: 640,
        ideal: width,
        max: 1920,
      },
      height: {
        min: 480,
        ideal: height,
        max: 1080,
      },
      ...(await narrowDownFacingMode(camera)),
    },
  };
  if (deviceId) constraints.video.deviceId = deviceId;
  const stream = await navigator.mediaDevices.getUserMedia(constraints);
  const settings = stream.getVideoTracks()[0].getSettings();
  return { stream, settings };
}

const narrowDownFacingMode = async (camera) => {
  // Filter some devices, known to be bad choices.
  const deviceBlackList = ["OBS Virtual Camera", "OBS-Camera"];

  const devices = (await navigator.mediaDevices.enumerateDevices())
    .filter(({ kind }) => kind === "videoinput")
    .filter(({ label }) => !deviceBlackList.includes(label))
    .filter(({ label }) => !label.includes("infrared"));
  if (devices.length > 2) {
    // Explicitly picking the first entry in the list of all videoinput
    // devices for as the default front camera and the last entry as the default
    // rear camera seems to be a good heuristic on some devices.
    const frontCamera = devices[0];
    const rearCamera = devices[devices.length - 1];

    switch (camera) {
      case "auto":
        return { deviceId: { exact: rearCamera.deviceId } };
      case "rear":
        return { deviceId: { exact: rearCamera.deviceId } };
      case "front":
        return { deviceId: { exact: frontCamera.deviceId } };
      default:
        return undefined;
    }
  } else {
    switch (camera) {
      case "auto":
        return { facingMode: { ideal: "environment" } };
      case "rear":
        return { facingMode: { exact: "environment" } };
      case "front":
        return { facingMode: { exact: "user" } };
      default:
        return undefined;
    }
  }
};

export function stopVideoStream(stream) {
  const tracks = stream.getTracks();
  tracks.forEach((track) => track.stop());
}
