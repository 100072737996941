<template>
  <div class="stylecontainer">
    <video
      :ref="(ref) => (video.ref = ref)"
      class="video"
      :class="{ mirror: video.mirror }"
      autoplay
      playsinline
    />
    <div class="controls">
      <button class="controls__control" @click="toggleMirror">
        <mirror-icon />
      </button>
      <button class="controls__control" @click="switchCamera">
        <switch-icon />
      </button>
      <button class="controls__control" @click="stopCamera">
        <close-icon />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import MirrorIcon from "@/components/video/MirrorIcon.vue";
import SwitchIcon from "@/components/video/SwitchIcon.vue";
import CloseIcon from "@/components/video/CloseIcon.vue";
import { getOperationSystem, getDevices } from "@/utils/navigator";
import Canvas from "@/utils/canvasDocument";

// eslint-disable-next-line no-undef
const props = defineProps({
  side: {
    type: String,
    default() {
      return "front";
    },
  },
});
// eslint-disable-next-line no-undef
const emit = defineEmits({
  start: () => true,
  stop: () => true,
});

const video = reactive({
  ref: null,
  mirror: false,
});
const canvas = ref(null);
const deviceIds = ref(null);

onMounted(async () => {
  deviceIds.value = (await getDevices()).map((device) => device.deviceId);
  const deviceId =
    props.side === "back" ? deviceIds.value[0] : deviceIds.value[1];
  await setStream(deviceId);
  canvas.value = new Canvas({
    video: video.ref,
  });
  emit("start");
});

async function setStream(deviceId) {
  stopStream();
  video.ref.srcObject = await navigator.mediaDevices.getUserMedia({
    video: {
      deviceId,
    },
    audio: false,
  });
  setMirror();
}

function stopStream() {
  if (video.ref.srcObject) {
    const tracks = video.ref.srcObject.getTracks();
    tracks.forEach((track) => track.stop());
    video.ref.srcObject = null;
  }
}

function setMirror() {
  if (["Android", "iOS"].includes(getOperationSystem())) {
    const deviceIndex = deviceIds.value.indexOf(
      video.ref.srcObject.getVideoTracks()[0].getSettings().deviceId
    );
    if (deviceIndex === 0) toggleMirror("back");
    else if (deviceIndex === 1) toggleMirror("front");
  } else toggleMirror("front");
}

function toggleMirror(side = null) {
  if (side === "front") video.mirror = true;
  else if (side === "back") video.mirror = false;
  else video.mirror = !video.mirror;
}

function switchCamera() {
  if (video.ref.srcObject && deviceIds.value.length > 1) {
    const deviceId = video.ref.srcObject
      .getVideoTracks()[0]
      .getSettings().deviceId;
    const deviceIndex = deviceIds.value.indexOf(deviceId);
    const newDeviceId =
      deviceIndex === deviceIds.value.length - 1
        ? deviceIds.value[0]
        : deviceIds[deviceIndex + 1];
    setStream(newDeviceId);
  }
}

function stopCamera() {
  stopStream();
  emit("stop");
}

// eslint-disable-next-line no-undef
defineExpose({
  screenshot: (config) => {
    return canvas.value.screenshot(config);
  },
  stop: () => {
    stopCamera();
  },
});
</script>

<style scoped>
.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

video.mirror {
  transform: scaleX(-1);
}

.controls {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  z-index: 9999;
}

.controls__control {
  padding: 5px 10px;
  width: auto;
  height: auto;
  margin: 10px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 10px;
  outline: none;
}

.controls__control svg {
  width: 32px;
  height: 32px;
}
</style>
