<template>
  <div class="custom-input-row">
    <div class="custom-input-wrapper">
      <label
        :class="['custom-label fs-16', { 'custom-error': props.error }]"
        :for="props.name"
        >{{ $t(props.label) }}
        <span v-if="props.required" class="custom-error">*</span></label
      >
      <input
        @click="setCaretPosition()"
        @paste.prevent="setPastedValue($event)"
        ref="inputRef"
        :class="['custom-input', { 'custom-error': props.error }]"
        :id="props.name"
        :type="props.inputType"
        :value="props.value"
        :disabled="props.disabled"
        :placeholder="$t(props.placeholder)"
        :autocomplete="props.autocomplete"
        @input="handleInput()"
      />
      <span
        :class="[
          'custom-counter',
          { 'custom-error': props.error || counterError },
        ]"
        v-if="props.counter"
        >{{ props.value.length }}/{{ props.counter }}</span
      >
    </div>
    <p class="custom-error" v-if="props.error">
      {{
        props.error
          ? parameterizedString($t(props.error), props.counter)
          : $t(props.error) || props.error === "Subject not found in MCDB"
          ? $t("common.error.mcdb")
          : $t(props.error)
      }}
    </p>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  inputType: {
    type: String,
    required: false,
    default: "text",
  },
  error: {
    type: String,
    required: false,
  },
  validator: {
    type: Function,
    required: false,
  },
  mask: {
    type: Function,
    required: false,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  counter: {
    type: [String, Number],
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  autocomplete: {
    type: String,
    default: () => "off",
  },
  width: {
    type: String,
    default: () => "200px",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  caretPosition: {
    type: Boolean,
    required: false,
    default: false,
  },
  required: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["update:error", "update:value"]);

const counterError = ref(false);

const validateLength = (value) => {
  if (value.length <= props.counter) return "";
  return `Поле должно содержать ${props.counter} символов`;
};

const inputRef = ref(null);

const setCaretPosition = (caretPosition = null) => {
  if (!caretPosition) {
    const input = inputRef.value;
    caretPosition = input.value.indexOf(" ") || input.value.length - 1;
    inputRef.value.setSelectionRange(caretPosition, caretPosition);
  } else if (caretPosition || caretPosition === 0) {
    inputRef.value.setSelectionRange(caretPosition, caretPosition);
  }
};

const setPastedValue = (event) => {
  let value = event.clipboardData.getData("text/plain");
  handleInput(value);
};

const handleInput = (value = null) => {
  if (!value) value = inputRef.value.value;
  let mask;
  if (props.mask && props.caretPosition) {
    mask = props.mask(value);
    value = mask.value;
    inputRef.value.value = value;
  } else if (props.mask) {
    value = props.mask(value);
    inputRef.value.value = value;
  }
  emits("update:value", value);
  let error = "";
  if (props.counter) error = validateLength(value);
  if (props.validator) error = props.validator(value) || error;
  if (props.caretPosition && mask.caretPosition)
    setCaretPosition(mask.caretPosition);
  emits("update:error", error);
};

const parameterizedString = (...args) => {
  const str = args[0];
  const params = args.filter((arg, index) => index !== 0);
  if (!str) return "";
  return str.replace(/%s[0-9]+/g, (matchedStr) => {
    const variableIndex = matchedStr.replace("%s", "") - 1;
    return params[variableIndex];
  });
};
</script>

<style scoped>
.custom-input-row {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: calc(v-bind("props.width") + 20px);
  min-height: 100px;
}

.custom-input-wrapper {
  position: relative;
  width: 100%;
}

.custom-input-wrapper:active {
  outline: #a8a8a8;
}

.custom-input {
  transition: all 0.3s;
  padding: 5px 20px;
  border: none;
  border-radius: 6px;
  position: relative;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  margin: 10px 10px 0 10px;
  line-height: 6ex;
  background-color: rgb(19, 19, 19);
  color: #fff;
}

.custom-input:focus {
  border-color: transparent;
  outline: 1px solid #a8a8a8;
}

.custom-counter {
  display: none;
  transition: all 0.3s;
  position: absolute;
  top: 20%;
  z-index: 1;
  background-color: white;
  padding: 0 5px;
  border-radius: 3px;
}

.custom-label {
  color: #fff;
  margin: 12px;
}

.custom-counter {
  font-size: 12px;
  right: 20px;
}

.custom-input:focus + .custom-label,
.custom-input:focus + .custom-label + .custom-counter {
  color: #a8a8a8;
}

.custom-input:focus + .custom-label + .custom-error,
.custom-error,
.custom-error:focus,
.custom-input:focus + .custom-error {
  outline-color: red;
  color: red;
}

p.custom-error {
  min-height: 16px;
  margin: 5px 0 0 20px;
  text-align: start;
  font-size: 12px;
}

input[type="tel"] {
  font-size: 16px;
}
</style>
