import { instance } from "@/plugins/axios";

export const f2f = async (session, image1, image2) => {
  const fd = new FormData();
  fd.append("main_session_id", session);
  fd.append("image_1", image1);
  fd.append("image_2", image2);
  try {
    return await instance.post("/face2face/", fd);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
