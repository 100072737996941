/**
 * Canvas class
 * @property {HTMLVideoElement} video
 * @property {Object} size - size property must contain height and width keys
 */

class Canvas {
  constructor(props) {
    if (typeof props !== "undefined") {
      this.canvas = document.createElement("canvas");
      this.context = this.canvas.getContext("2d");
      this.video = props.video;
      this.size = props.size;
    }
  }

  getPhotoAsBase64() {
    this.canvas.setAttribute("width", `${this.size.width}`);
    this.canvas.setAttribute("height", `${this.size.height}`);
    this.context.drawImage(this.video, 0, 0, this.size.width, this.size.height);
    return this.canvas.toDataURL("image/jpeg", 0.5);
  }

  convertToFile(dataURL, filename) {
    const array = dataURL.split(",");
    const mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
    const temp = atob(array[1]);
    let n = temp.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = temp.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getUserPhotoAsFile(photoBase64) {
    if (!photoBase64) {
      const photoB64 = this.getPhotoAsBase64();
      return this.convertToFile(photoB64, "facePhoto.jpeg");
    }
    return this.convertToFile(photoBase64, "facePhoto.jpeg");
  }

  getDocumentAsFile() {
    const documentBase64 = this.getDocumentAsBase64();
    return this.convertToFile(documentBase64, "document.png");
  }

  getDocumentAsBase64() {
    const size = this.calculateResolution();
    this.canvas.setAttribute("width", `${size.width}`);
    this.canvas.setAttribute("height", `${size.height}`);
    this.context.drawImage(
      this.video,
      this.video.videoWidth / 2 - size.width / 2,
      this.video.videoHeight / 2 - size.height / 2,
      size.width,
      size.height,
      0,
      0,
      size.width,
      size.height
    );
    return this.canvas.toDataURL("image/jpeg");
  }

  calculateResolution() {
    const multiplier = this.getMultiplier();
    return {
      width: this.size.width * multiplier,
      height: this.size.height * multiplier,
    };
  }

  getMultiplier() {
    if (window.innerWidth > 1171) return 1.15;
    else if (window.innerWidth > 651 && window.innerWidth < 1170) return 1.2;
    else return 2;
  }
}

export default Canvas;
