<template>
  <div class="container">
    <div class="alert">
      <div class="alert__text">
        {{ $t(props.text) }}
      </div>
      <custom-button
        :text="button.text"
        :loading="button.loading"
        @click="button.action"
      />
    </div>
  </div>
</template>

<script setup>
import { markRaw, reactive } from "vue";
import router from "@/router";
import { useUserDataStore } from "@/stores/userData";
import CustomButton from "@/components/CustomButton.vue";
import { unsetApiKey } from "@/plugins/axios";

const userDataStore = useUserDataStore();

// eslint-disable-next-line no-undef
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const button = reactive({
  text: "common.alert.button-text",
  loading: false,
  action: markRaw(() => {
    unsetApiKey();
    button.loading = true;
    userDataStore.isEdocument = false;
    router.push("/document");
    button.loading = false;
  }),
});
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.alert {
  background: #363636;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 250px;
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0 0 10px 999px rgba(255, 255, 255, 0.3);
}
.alert__text {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
