import { ref, reactive, watch, getCurrentInstance } from "vue";

export default function useStepper(config) {
  const instance = getCurrentInstance();

  const queue = ref(config?.queue ?? []);
  const steps = config?.steps ?? {};
  const delay = config?.delay ?? 500;
  const timer = config?.timer ?? 35000;
  const successCallback = config?.success ?? (() => {});
  const failureCallback = config?.failure ?? (() => {});
  const transitionCallback = config?.transition ?? (() => {});

  const step = ref(queue.value.shift());
  const current = ref({ ...steps[step.value] });
  const timeout = { value: runTimeout() };
  const finished = { value: false };

  watch(
    () => step.value,
    (newStep) => {
      current.value = { ...steps[newStep] };
      clearTimeout(timeout.value);
      timeout.value = runTimeout();
    }
  );

  function next(expectedStep) {
    if (finished.value === true) return true;
    if (step.value === expectedStep) {
      transition();
      setTimeout(() => {
        if (queue.value?.length > 0) {
          step.value = queue.value.shift();
        } else {
          success();
        }
      }, delay);
      return queue.value.length === 0;
    }
    return false;
  }

  function success() {
    successCallback(current.value);
    finish("success");
  }

  function failure() {
    failureCallback(current.value);
    finish("failure");
  }

  function transition() {
    transitionCallback(current.value);
  }

  function finish(status) {
    instance.emit("finish", {
      status,
    });
    finished.value = true;
    clearTimeout(timeout.value);
  }

  function runTimeout() {
    return setTimeout(() => {
      failure();
    }, timer);
  }

  return reactive({
    current,
    next,
    step,
    force: () => next(step.value),
    isLast: () => queue.value.length === 0,
  });
}
