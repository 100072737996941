import { instance } from "@/plugins/axios";

export const createSession = async () => {
  try {
    return await instance.post("/main/session/");
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
