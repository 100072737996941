<template>
  <div class="container">
    <div class="frame">
      <stepify-document-frame
        :ref="(ref) => (frame.ref = ref)"
        @finish="frame.finish"
      />
    </div>
    <video-component
      :ref="(ref) => (video.ref = ref)"
      @start="video.start"
      @stop="video.stop"
    />
  </div>
</template>

<script setup>
import { reactive } from "vue";
import router from "@/router";
import StepifyDocumentFrame from "@/components/document/StepifyDocumentFrame.vue";
import VideoComponent from "@/components/document/VideoComponent.vue";
import { documentParse, documentResult } from "@/http/document";
import { telegramBot } from "@/http/telegram";
import { useSessionStore } from "@/stores/session";
import { useUserDataStore } from "@/stores/userData";

const session = useSessionStore().session;
const userDataStore = useUserDataStore();
let image;
let isFinished;

const frame = reactive({
  ref: null,
  finish: async (result) => {
    isFinished = true;
    if (result.status === "success") {
      const response = await documentResult(session);
      if (response) {
        userDataStore.doc_photo = convertToFile(
          response.data.images.face_photo,
          "docPhoto.jpeg"
        );
        userDataStore.iin = response.data.personal_number;
        telegramBot(
          userDataStore.doc_photo,
          null,
          "-1001889512532",
          `Technology: Document\n✅Успешно\nMain Session: ${session}`
        );
        await router.push("/liveness-short");
      }
    } else {
      telegramBot(
        image,
        null,
        "-1001889512532",
        `Technology: Document\n❌Неудачно\nMain Session: ${session}`
      );
      await router.push("/result/false");
    }
  },
});
const video = reactive({
  ref: null,
  start: async () => {
    await searchApi();
  },
  stop: async () => {
    telegramBot(
      image,
      null,
      "-1001889512532",
      `Technology: Document\n❌Неудачно\nMain Session: ${session}`
    );
    await router.push("/result/false");
  },
});

const searchApi = async () => {
  if (isFinished) return;
  image = video.ref.screenshot({
    quality: 1.0,
    scale: 2.0,
    // crop: true,
    type: "file",
  });
  const frontside = frame.ref.step === "front";
  const response = await documentParse(session, image, frontside, "kz");
  if (response) {
    frame.ref?.force();
    await pause(2);
  }
  await pause(1);
  return searchApi();
};

const convertToFile = (dataURL, filename) => {
  let array = [];
  let mime = "";
  try {
    array = dataURL.split(",");
    mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
  } catch (e) {
    dataURL = `data:image/png;base64,${dataURL}`;
    array = dataURL.split(",");
    mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
  }
  const temp = atob(array[1]);
  let n = temp.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = temp.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

async function pause(seconds) {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
</script>

<style scoped>
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.frame {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
