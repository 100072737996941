import { instance } from "@/plugins/axios";

export const telegramBot = async (img1, img2, chatId, messageText) => {
  const fd = new FormData();

  if (img1) {
    fd.append("img_1", img1);
  }

  if (img2) {
    fd.append("img_2", img2);
  }

  fd.append("message_text", messageText);
  fd.append("chat_id", chatId);
  console.log(fd.values());
  try {
    return await instance.post("/telegram-bot/custom/", fd);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
