import { createRouter, createWebHistory } from "vue-router";
import InfoView from "@/views/InfoView";
import RequestFormView from "@/views/RequestFormView.vue";
import ApproveFormView from "@/views/ApproveFormView.vue";
import DocumentView from "@/views/DocumentView.vue";
import ShortView from "@/views/ShortView.vue";
import ResultView from "@/views/ResultView.vue";
import EmailView from "@/views/EmailView.vue";

const routes = [
  {
    path: "/",
    name: "Antiazart - Заявление",
    component: RequestFormView,
  },
  {
    path: "/info",
    name: "Antiazart - Информация",
    component: InfoView,
  },
  {
    path: "/approve-form",
    name: "Antiazart - Подтверждение СМС",
    component: ApproveFormView,
  },
  {
    path: "/document",
    name: "Antiazart - Сканирование документа",
    component: DocumentView,
  },
  {
    path: "/liveness-short",
    name: "Antiazart - Верификация личности",
    component: ShortView,
  },
  {
    path: "/email-view",
    name: "Antiazart - отправка почты",
    component: EmailView,
  },
  {
    path: `/result/:success`,
    name: "Antiazart - Результат",
    component: ResultView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
