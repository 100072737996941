import { instance } from "@/plugins/axios";

export const livenessShort = async (session, file) => {
  const formData = new FormData();
  formData.append("main_session_id", session);
  formData.append("file", file);
  try {
    return await instance.post("/liveness/detect/", formData);
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
