import { defineStore } from "pinia";

export const useUserDataStore = defineStore({
  id: "userDataStore",
  state: () => ({
    phone: "",
    iin: "",
    doc_photo: null,
    face_photo: null,
    isFromFirstPage: false,
    isEdocument: true,
    otp_code: "",
  }),
});
