<template>
  <div class="wrapper">
    <div class="header">
      <h1 class="header__main fs-32">{{ $t("approve.header") }}</h1>
      <h2 class="header__secondary fs-16">
        {{ $t("approve.sub-header") }}
      </h2>
    </div>
    <div class="form">
      <h2 class="form__header fs-20">{{ $t("approve.form.header") }}</h2>
      <form action="" class="form__content">
        <custom-input
          @keydown.enter.prevent="button.submit"
          width="100%"
          v-model:error="code.error"
          v-model:value="code.data"
          :validator="code.validate"
          :mask="codeMask"
          placeholder="approve.form.code-placeholder"
          label="approve.form.code-label"
          name="code"
          input-type="tel"
          counter="6"
          :required="true"
        />
        <div class="form__counter fs-16">
          <p>{{ $t("approve.form.tries") + ": " + counter }}</p>
        </div>
      </form>
      <custom-button
        text="common.button"
        width="70%"
        @click="button.submit"
        :loading="button.loading"
        :error="button.error"
        :disabled="isDisabled"
      />
    </div>
    div
    <div class="wrapper__help-text fs-16">
      <a @click="router.push('/info')">{{ $t("approve.info") }}</a>
    </div>
    <div class="footer fs-16">
      <div class="footer-content">
        <div class="footer-content__left fs-16">
          <p>{{ $t("common.footer.copyright") }}</p>
        </div>
        <div class="footer-content__right fs-16">
          <p>{{ $t("common.footer.mail") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, markRaw, reactive, onMounted, ref, watch } from "vue";
import router from "@/router";
import CustomInput from "@/components/CustomInput";
import CustomButton from "@/components/CustomButton.vue";
import { approveCode } from "@/http/edocument";
import { telegramBot } from "@/http/telegram";
import { pdf } from "@/http/pdf";
import { useUserDataStore } from "@/stores/userData";
import { useSessionStore } from "@/stores/session";
import { changeBackendUrl } from "@/plugins/axios";

const userDataStore = useUserDataStore();
const sessionStore = useSessionStore();

const counter = ref(3);

watch(counter, async (newCounter) => {
  if (newCounter === 0) {
    await telegramBot(
      null,
      null,
      "-1001889512532",
      `Technology: E-Document\n❌Неудачно\nMain Session: ${sessionStore.session}`
    );
    await router.push("/result/false");
  }
});

const code = reactive({
  data: "",
  error: "",
  validate: markRaw((value) => {
    if (value.length === 0) return "common.error.empty";
    value = value.replace(/\D+/g, "");
    if (value.length !== 6 || !/^[0-9]*$/.test(value))
      return "common.error.length";
    return "";
  }),
});
const button = reactive({
  error: "",
  loading: false,
  submit: markRaw(async () => {
    button.error = "";
    button.loading = true;
    const response = await approveCode(
      sessionStore.session,
      userDataStore.iin,
      code.data
    );
    if (response.status === 200) {
      userDataStore.doc_photo = convertToFile(
        response.data.face_photo,
        "docPhoto.jpeg"
      );
      userDataStore.otp_code = code.data;
      await telegramBot(
        userDataStore.doc_photo,
        null,
        "-1001889512532",
        `Technology: E-Document\n✅Успешно\nMain Session: ${sessionStore.session}`
      );
      changeBackendUrl("https://antiazart.kz/api/v1");
      await pdf(userDataStore.otp_code, sessionStore.session);
      changeBackendUrl("https://test.biometric.kz/v1");
      await router.push("/liveness-short");
    } else {
      counter.value--;
      if (
        response.data.detail === "Access to document not found or is not active"
      ) {
        button.error = "common.error.doc-access";
      } else button.error = response.data.detail;
    }
    button.loading = false;
  }),
});

const codeMask = (value) => {
  if (value.length > 6) return value.slice(0, 6);

  return value;
};

const convertToFile = (dataURL, filename) => {
  let array = [];
  let mime = "";
  try {
    array = dataURL.split(",");
    mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
  } catch (e) {
    dataURL = `data:image/png;base64,${dataURL}`;
    array = dataURL.split(",");
    mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
  }
  const temp = atob(array[1]);
  let n = temp.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = temp.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const isDisabled = computed(() => {
  if (code.data.length === 0 || code.error.length > 0) return true;

  return false;
});

onMounted(() => {
  if (!userDataStore.isFromFirstPage) router.push("/");
});
</script>

<style scoped>
.wrapper {
  background: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.header {
  margin-top: 50px;
}
.header__main,
.header__secondary,
.form__header,
.wrapper__help-text {
  text-align: center;
  text-transform: uppercase;
}
.header__main,
.form__header,
.wrapper__help-text a {
  color: #fff;
}
.wrapper__help-text a {
  cursor: pointer;
  text-decoration: underline;
}
.header__secondary {
  margin-top: 40px;
  color: #6bbf43;
}
.form {
  width: 70%;
  max-width: 700px;
}
.form__header {
  margin-bottom: 30px;
}
.form__content {
  background: #ffffff36;
  padding: 25px 50px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.form__counter {
  color: #fff;
}
.wrapper__help-text {
  font-size: 20px;
}
.footer {
  height: 50px;
  background: #ffffff36;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.footer-content {
  height: 100%;
  width: 730px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-content__right,
.footer-content__left {
  color: #ffffffc2;
  font-size: 16px;
}
.footer-content__left {
  margin-left: 20px;
}
.footer-content__right {
  margin-right: 20px;
}

@media screen and (max-width: 650px) {
  .form {
    width: 95%;
  }
  .form__content {
    /*padding: 30px 30px;*/
    width: 100%;
  }
  .header__secondary {
    margin: 15px 5px 15px 5px;
  }
  .wrapper__help-text {
    margin-top: 15px;
  }
  .footer-content {
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-content__left,
  .footer-content__right {
    margin: 5px 0;
  }
}
</style>
