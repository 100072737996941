import axios from "axios";

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
// const ANTIAZART_BACKEND_URL = process.env.ANTIAZART_BACKEND_URL;

export const instance = axios.create({
  baseURL: BACKEND_URL,
});

export const setApiKey = (api_key) => {
  instance.defaults.headers["Authorization"] = `API_KEY ${api_key}`;
};

export const unsetApiKey = () => {
  delete instance.defaults.headers["Authorization"];
};

export const changeBackendUrl = (url) => {
  instance.defaults.baseURL = url;
};
