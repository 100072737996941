<template>
  <div class="wrapper">
    <div class="header">
      <h1 class="header__main fs-32">
        {{ $t("email.header") }}
      </h1>
      <h2 class="header__secondary fs-16">
        {{ $t("email.sub-header") }}
      </h2>
    </div>
    <div class="container">
      <div class="container__mail fs-20" ref="mail"></div>
      <p class="container__text fs-16">
        {{ $t("email.time-amount.header") }}
      </p>
      <div class="container__radio-wrapper">
        <span class="container__text fs-20">
          {{ $t("email.time-amount.6-months") }}
        </span>
        <label class="switch">
          <input type="checkbox" v-model="checked" @change="handleChange" />
          <span class="slider"></span>
        </label>
        <span class="container__text fs-20">
          {{ $t("email.time-amount.1-year") }}
        </span>
      </div>
      <div class="container__additional-email">
        <p class="container__text fs-16">
          {{ $t("email.mail.header") }}
        </p>
        <custom-input
          name="email"
          type="email"
          placeholder="email.mail.email-placeholder"
          label="email.mail.email-label"
          v-model:value="email.data"
          v-model:error="email.error"
          :validator="email.validate"
          width="300px"
        />
      </div>
      <div class="container__radio-button">
        <custom-button
          text="email.mail.button"
          width="100%"
          @click="button.submit"
          :loading="button.loading"
          :error="button.error"
          :disabled="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CustomButton from "@/components/CustomButton.vue";
import { markRaw, onMounted, reactive, ref } from "vue";
import { previewEdoc, previewDoc, send } from "@/http/email";
import { changeBackendUrl, unsetApiKey } from "@/plugins/axios";
import CustomInput from "@/components/CustomInput.vue";
import router from "@/router";
import { useUserDataStore } from "@/stores/userData";
import { useSessionStore } from "@/stores/session";

const userDataStore = useUserDataStore();
const sessionStore = useSessionStore();
const checked = ref(false);
const mail = ref("");

const email = reactive({
  data: "",
  error: "",
  validate: markRaw((value) => {
    if (value && !value.includes("@")) return "common.error.email";
    return "";
  }),
});
const button = reactive({
  error: "",
  loading: false,
  submit: markRaw(async () => {
    button.loading = true;
    const response = await send(
      checked.value ? 12 : 6,
      email.data ? email.data : null,
      sessionStore.session,
      userDataStore.iin,
      userDataStore.isEdocument
    );
    if (response.status === 200) {
      await router.push("/result/true");
    } else {
      button.error = "common.error.something-wrong";
    }
    button.loading = false;
  }),
});

const handleChange = () => {
  const period = document.getElementById("period");
  period.innerText = checked.value ? "1 год" : "6 месяцев";
};

onMounted(async () => {
  if (!userDataStore.isFromFirstPage) await router.push("/");
  changeBackendUrl("https://antiazart.kz/api/v1");
  unsetApiKey();
  const previewResult = userDataStore.isEdocument
    ? await previewEdoc(
        userDataStore.iin,
        sessionStore.session,
        userDataStore.otp_code
      )
    : await previewDoc(userDataStore.iin, sessionStore.session);
  if (previewResult) {
    mail.value.innerHTML = previewResult.data;
    handleChange();
  }
});
</script>

<style scoped>
.wrapper {
  background: #000;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header__main,
.header__secondary {
  text-align: center;
  text-transform: uppercase;
}
.header__main {
  color: #fff;
}
.header__secondary {
  margin-top: 40px;
  color: #6bbf43;
}
.container {
  width: 80%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container > * {
  margin-bottom: 20px;
}
.container__mail {
  border-radius: 10px;
}
.container__text {
  color: #6bbf43;
}
.container__radio-wrapper {
  width: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.container__additional-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6bbf43;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6bbf43;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
