import { instance } from "@/plugins/axios";

export const previewEdoc = async (iin, main_session, code) => {
  const payload = {
    iin,
    main_session,
    code,
  };
  try {
    return await instance.post(`/preview/edoc/`, payload);
  } catch (e) {
    console.log(e.response);
    return e.response;
  }
};

export const previewDoc = async (iin, main_session, edoc) => {
  const payload = {
    iin,
    main_session,
    edoc,
  };
  try {
    return await instance.post(`/preview/dv/`, payload);
  } catch (e) {
    console.log(e.response);
    return e.response;
  }
};

export const send = async (period, email, session, iin, edoc) => {
  const payload = {
    period,
    email,
    main_session: session,
    iin,
    edoc,
  };
  try {
    return await instance.post("/send/", payload);
  } catch (e) {
    console.log(e.response);
    return e.response;
  }
};
