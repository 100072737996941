import { instance } from "@/plugins/axios";

export async function documentParse(session, image, frontside) {
  const formData = new FormData();
  formData.append("main_session_id", session);
  formData.append("document_image", image);
  formData.append("frontside", frontside);
  formData.append("country_code", "kz");
  try {
    return await instance.post("/doc-verification/search/document/", formData);
  } catch (e) {
    return null;
  }
}

export async function documentResult(session) {
  const formData = new FormData();
  formData.append("main_session_id", session);
  formData.append("counrtry_code", "kz");
  try {
    return instance.post("/doc-verification/parse-result/", formData);
  } catch (e) {
    return null;
  }
}
