function getResolution(video) {
  const settings = video.srcObject.getVideoTracks()[0]?.getSettings();
  return {
    width: settings?.width,
    height: settings?.height,
  };
}

function convertToFile(dataURL, filename) {
  const array = dataURL.split(",");
  const mime = array[0].match(/:(.*?)/)[1] || ".jpeg";
  const temp = atob(array[1]);
  let n = temp.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = temp.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class Canvas {
  constructor(config) {
    this.canvas = document.createElement("canvas");
    this.context = this.canvas.getContext("2d");
    this.video =
      config?.video ??
      (() => {
        throw new Error("No video");
      })();
  }

  screenshot(options) {
    const format = options?.type ?? "base64";
    const quality = options?.quality ?? 1.0;
    const scale = options?.scale ?? 1.0;
    const crop = options?.crop ?? false;

    const resolution = getResolution(this.video);
    const width = resolution.width * scale;
    const height = resolution.height * scale;

    this.context.scale(scale, scale);

    this.canvas.setAttribute("width", width.toString());
    this.canvas.setAttribute("height", height.toString());

    if (crop) {
      this.context.drawImage(
        this.video,
        resolution.width / 4,
        resolution.height / 4,
        resolution.width - resolution.width / 2,
        resolution.height - resolution.height / 2,
        0,
        0,
        width,
        height
      );
    } else {
      this.context.drawImage(this.video, 0, 0, width, height);
    }

    const base64 = this.canvas.toDataURL("image/jpeg", quality);

    if (format === "base64") {
      return base64;
    }

    if (format === "file") {
      return convertToFile(base64, "image.jpeg");
    }

    return null;
  }
}

export default Canvas;
