<template>
  <div class="container">
    <main class="wrapper">
      <img src="../assets/short/company-logo.png" class="logo" />
      <h3 class="h3">{{ $t("short.header") }}</h3>
      <svg-no-animation
        class="imgForward"
        :border-color="svgColors.borderColor"
        :face-color="svgColors.faceColor"
      />
      <div class="prediction">
        <p class="p">{{ $t("short.tries") + ": " + counter }}</p>
      </div>
      <div class="takePhoto">
        <button class="takePhotoBtn" @click="sendImage">
          <img src="../assets/short/camera.svg" />
        </button>
      </div>
    </main>
    <video ref="stream" class="videoStream" autoplay muted playsinline />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, reactive, onBeforeUnmount } from "vue";
import SvgNoAnimation from "@/components/SvgNoAnimation.vue";
import { getVideoStreamData, stopVideoStream } from "@/utils/camera";
import Canvas from "@/utils/canvas";
import { livenessShort } from "@/http/livenessShort";
import { f2f } from "@/http/f2f";
import { telegramBot } from "@/http/telegram";
import { useSessionStore } from "@/stores/session";
import { useUserDataStore } from "@/stores/userData";
import router from "@/router";

const session = useSessionStore().session;
const userData = useUserDataStore();

const stream = ref(null);
const counter = ref(3);
const isFinished = ref(false);

const photoFile = ref(null);

const prediction = reactive({
  prediction: null,
  detected: false,
});

const videoSetting = reactive({
  size: {},
  stream: null,
  settings: null,
});

const svgColors = reactive({
  borderColor: "white",
  faceColor: "white",
  innerBackground: "#000",
});

watch(counter, async (newCounter) => {
  if (newCounter === 0) {
    changeSvgColors("red");
    await pause(1);
    isFinished.value = true;
    await telegramBot(
      photoFile.value,
      null,
      "-1001889512532",
      `Technology: Liveness\n❌Неудачно\nMain Session: ${session}`
    );
    await router.push("/result/false");
  }
});

async function pause(seconds) {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

const changeSvgColors = (color) => {
  svgColors.borderColor = color;
  svgColors.faceColor = color;
};

const configureVideoStream = async () => {
  let video;
  if (window.innerWidth > 650) {
    video = await getVideoStreamData("auto");
  } else {
    video = await getVideoStreamData("front");
  }
  videoSetting.stream = video.stream;
  videoSetting.settings = video.settings;
  videoSetting.size.height = video.settings.height;
  videoSetting.size.width = video.settings.width;
  stream.value.srcObject = video.stream;
};

const sendImage = async () => {
  let canvas;
  if (window.innerWidth < 650) {
    canvas = new Canvas({
      video: stream.value,
      size: { height: 1280, width: 720 },
    });
  } else {
    canvas = new Canvas({ video: stream.value, size: videoSetting.size });
  }
  photoFile.value = canvas.getUserPhotoAsFile();
  const response = await livenessShort(session, photoFile.value);
  if (response.status === 200) {
    prediction.value = response.data;
    prediction.prediction = response.data.prediction;
    prediction.detected = response.data.detected;

    if (prediction.detected) {
      changeSvgColors("green");
      await pause(1);
      isFinished.value = true;
      userData.face_photo = photoFile.value;
      await telegramBot(
        userData.face_photo,
        null,
        "-1001889512532",
        `Technology: Liveness\n✅Успешно\nMain Session: ${session}`
      );
      const f2fResponse = await f2f(
        session,
        userData.doc_photo,
        userData.face_photo
      );
      if (f2fResponse.data.result) {
        await telegramBot(
          userData.doc_photo,
          userData.face_photo,
          "-1001889512532",
          `Technology: F2F\n✅Успешно\nMain Session: ${session}`
        );
        await router.push("/email-view");
      } else {
        await telegramBot(
          userData.face_photo,
          userData.doc_photo,
          "-1001889512532",
          `Technology: F2F\n❌Неудачно\nMain Session: ${session}`
        );
        await router.push("/result/false");
      }
    } else {
      changeSvgColors("red");
      await pause(1);
      changeSvgColors("white");
      counter.value -= 1;
    }
  } else {
    changeSvgColors("red");
    await pause(1);
    changeSvgColors("white");
    counter.value -= 1;
  }
};

onMounted(async () => {
  if (!userData.isFromFirstPage) await router.push("/");
  await configureVideoStream();
});

onBeforeUnmount(() => {
  stopVideoStream(stream.value.srcObject);
});
</script>

<style scoped>
.container {
  position: relative;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
  width: 100vw;
  height: 90vh;
}

.logo {
  z-index: 2;
}

.h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.videoStream {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  z-index: -1;
}

.imgForward {
  z-index: 1;
  border-radius: 50%;
  aspect-ratio: 1;
  height: 500px;
  width: 400px;
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.5);
}

.prediction {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.p {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.takePhoto {
  z-index: 1;
}

.takePhotoBtn {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
}

.takePhotoBtn:active {
  width: 45px;
  height: 45px;
}

@media screen and (max-width: 650px) {
  .imgForward {
    width: 300px;
    height: 400px;
  }
}
</style>
