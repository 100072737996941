<template>
  <div class="container">
    <div class="border">
      <div class="text">
        {{ $t(props.text) }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  state: {
    type: String,
    default() {
      return "neutral";
    },
    validator(state) {
      return ["success", "failure", "neutral"].includes(state);
    },
  },
  text: {
    type: String,
    required: true,
    default() {
      return "Default Text";
    },
    validator(text) {
      return text?.length > 0;
    },
  },
});

const states = reactive({
  success: {
    colors: {
      border: "green",
      background: "rgba(0, 128, 0, .1)",
    },
  },
  failure: {
    colors: {
      border: "red",
      background: "rgba(255, 0, 0, .1)",
    },
  },
  neutral: {
    colors: {
      border: "white",
      background: "rgba(255, 255, 255, .1)",
    },
  },
});
const colors = computed(() => states[props.state].colors);
</script>

<style scoped>
.border {
  padding: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 3px v-bind(colors [ "border" ]) solid;
  background: v-bind(colors [ "background" ]);
  border-radius: 5%;
  width: 375px;
  height: 245px;
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.8);
}

.text {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 15px;
  text-align: center;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 100%;
}

@media screen and (min-width: 650px) {
  .border {
    width: 745px;
    height: 475px;
  }
}

@media screen and (min-width: 651px) and (max-width: 1170px) {
  .border {
    width: 535px;
    height: 345px;
  }
}

@media screen and (max-width: 650px) {
  .border {
    width: 375px;
    height: 245px;
  }
}
</style>
